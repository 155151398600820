import { createSlice } from "@reduxjs/toolkit";
import { createLogin, logout } from "./login.actions";
import toast from "react-hot-toast";

const INITIAL_STATE = {
  loginResponse: {},
  logoutResponse: null,
};

const LoginSlice = createSlice({
  name: "login",
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(createLogin.fulfilled, (state, action) => {
      state.loginResponse = action.payload;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      if (action.payload.responseCode === 0) {
        localStorage.clear();
        window.location.href = "/";
      } else {
        toast.error("Something went wrong please try later");
      }
    });
  },
});

const { reducer } = LoginSlice;
export default reducer;
