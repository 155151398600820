import { Apis } from "Redux/APIs";
import httpCommon from "Redux/http/http-common";
class LoginService {
  userLogin(data) {
    return httpCommon.post(Apis.Login_login, data);
  }
  userLogout(data){
    return httpCommon.post(
      `${Apis.Login_logout}?refreshToken=${data}`
    );
  }
}
export default new LoginService();
