import React from "react";
import Login from "Containers/Login";
import Upload from "./Containers/Upload";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";

const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <React.Suspense fallback={"loading"}>
          <Routes>
            <Route
              path="/upload"
              element={
                <ProtectedRoute>
                  <Upload />
                </ProtectedRoute>
              }
            />
            <Route exact path="/" element={<Login />} />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </div>
  );
};

export default App;
