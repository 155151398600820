import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
	formControl: {
		// margin: theme.spacing(1),
		minWidth: '100%',
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

/**
 * @fileOverview Reusable Input field
 * @component
 * @author Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * @author Athul George <athul@trillium-technologies.com>
 * @param {props} props will contain all the latest values for the autocomplete like value, onChange,Label
 * @return {JSXElement}
 * @example
 * return(
 * <Input/>
 * )
 */
function Input(props) {
	const classes = useStyles();
	// const [value, setValues] = useState(props.value ? props.value : '')
	/**
	 * onChange of datepicker
	 */
	const handleInput = (event) => {
		// setValues(event.target.value)
		if (props.onChange) {
			props.onChange(event);
		}
	};

	return (
		<FormControl className={classes.formControl}>
			<TextField
				tabIndex={props.tabIndex ? props.tabIndex : null}
				onChange={handleInput}
				fullWidth
				type={props.type ? props.type : 'text'}
				disabled={props.disabled ? props.disabled : false}
				id='standard-basic'
				label={props.placeholder ? props.placeholder : ''}
				{...props}
				inputProps={{
					autoComplete: 'off',
				}}
				variant='outlined'

				// size="small"
			/>
		</FormControl>
	);
}
export default Input;
