import { createAsyncThunk } from "@reduxjs/toolkit";
import LoginService from "./login.services";

export const createLogin = createAsyncThunk(
  "login/userLogin",
  async (credentials) => {
    try {
      const res = await LoginService.userLogin(credentials);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const logout = createAsyncThunk(
  "login/userLogout",
  async (refreshToken) => {
    try {
      const res = await LoginService.userLogout(refreshToken);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
