import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./Login/login.reducer";
import uploadReducer from "./Upload/upload.reducer";
import { combineReducers } from "redux";
const rootReducer = combineReducers({
  login: loginReducer,
  upload: uploadReducer,
});
const newStore = configureStore({
  reducer: rootReducer,
});

export default newStore;
