import { createAsyncThunk } from "@reduxjs/toolkit";
import UploadService from "./upload.services";

export const uploadFile = createAsyncThunk(
  "upload/uploadFile",
  async (fileData) => {
    try {
      const res = await UploadService.uploadFile(fileData);
      return res.data;
    } catch (error) {
      console.log(error, "api error");
    }
  }
);
export const uploadStatus = createAsyncThunk(
  "upload/uploadStatus",
  async (data) => {
    try {
      const res = await UploadService.uploadStatus(data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const downloadFile = createAsyncThunk(
  "upload/downloadFile",
  async (data) => {
    try {
      console.log(data, "download");
      const res = await UploadService.download(data);
      console.log(res, "dowload response");
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
