import axios from "axios";
import environment from "environments/environment";
const { baseUrl } = environment;
const token = localStorage.getItem("access_token") || null;
const Bearer = "Bearer ";
export default axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    Authorization: Bearer + token,
  },
});
