import { createSlice } from "@reduxjs/toolkit";
import { uploadFile, uploadStatus, downloadFile } from "./upload.actions";

const INITIAL_STATE = {
  uploadResponse: {},
  file: null,
  fileType: null,
  fileUploadStatus: {},
  progress: {},
};
const UploadSlice = createSlice({
  name: "upload",
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      state.uploadResponse = action.payload;
    });
    builder.addCase(uploadStatus.fulfilled, (state, action) => {
      state.fileUploadStatus = action.payload;
    });
    builder.addCase(downloadFile.fulfilled, (state, action) => {
      state.file = action.payload;
    });
  },
});
const { reducer } = UploadSlice;
export default reducer;
