export const Apis = {
  //Login
  Login_login: "trillium-clinic-service/v1/authentication/clinic/user/login",
  Login_logout: "trillium-clinic-service/v1/authentication/clinic/user/logout",

  //

  authApi: "/apointments-patient-api/v1/authenticate/login",
  fetchDetailsApi: "/apointments-patient-api/v1/authenticate",
  fetchMessagesApi: "/messaging-api/v1/chat/patient/messages/list",
};
